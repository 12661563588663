<template>
  <div v-if="sessions.length" class="academy-page">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <legend class="large-legend">
      <strong>{{ $t('ACADEMY') }}</strong>
    </legend>
    <Widget v-for="session in sessions" :key="session.id">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <Card>
            <template #title>
              <div v-html="session.name"></div>
            </template>
            <template #content> <div v-html="session.description"></div> </template>
          </Card>
        </b-col>

        <b-col lg="6" md="6" sm="12">
          <Card v-for="content in session.contents" :key="content.content" class="content-card">
            <template #title>
              {{ contentById(content.content).name }}
            </template>
            <template #content>
              <AttachmentList
                :attachments="contentById(content.content).contentAttachments"
                :deletable="false"
              />
            </template>
            <template #subtitle>
              <div v-html="contentById(content.content).description"></div>
            </template>
          </Card>
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Card from 'primevue/card';
import AttachmentList from '@/components/AttachmentList';

export default {
  name: 'AcademyPage',
  components: {
    Widget,
    Breadcrumbs,
    Card,
    AttachmentList,
  },
  computed: {
    ...mapGetters({
      sessions: 'getSessions',
      contents: 'getContents',
    }),
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Academy', route: { name: 'AcademyPage' } },
      ];
    },
    contentById() {
      return (id) => {
        const content = this.contents.find((content) => content.id === id);
        if (content) {
          return content;
        } else {
          return {
            name: 'Loading...',
            description: 'Content is currently unavailable.',
            contentAttachments: [],
          };
        }
      };
    },
  },
  methods: {
    ...mapActions({
      fetchSessions: 'fetchSessions',
      fetchContents: 'fetchContents',
    }),
    async fetchAllContents() {
      await this.fetchContents();
      await this.fetchSessions();
    },
  },

  created() {
    this.fetchAllContents();
  },
};
</script>

<style scoped>
.class-card,
.content-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.large-legend {
  font-size: 2em;
  margin-bottom: 35px;
}
</style>
