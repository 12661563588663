<template>
  <div class="attachment-list">
    <div class="empty" v-if="!attachments || (attachments && attachments.length === 0)">
      <small>{{ $t('no_files_found') }}</small>
    </div>
    <div class="attachment-grid-container" v-if="attachments">
      <div class="attachment-grid" v-for="attachment in sortedAttachments" :key="attachment.id">
        <a v-if="attachment.filename" class="image" @click.prevent="openUrl(attachment)"
          ><img :src="fileImage(attachment.filename)"
        /></a>
        <a class="text small" @click.prevent="openUrl(attachment)">{{
          attachmentName(attachment)
        }}</a>
        <b-button
          v-if="deletable"
          @click.prevent="onDeleteAttachment(attachment.id)"
          :disabled="buttonsDisabled"
          variant="outline-danger"
        >
          <i class="fa fa-sm fa-times"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AttachmentList',
  components: {},
  props: {
    parentId: String,
    attachments: Array,
    attachmentType: String,
    buttonsDisabled: { default: false },
    deletable: { default: true },
    signed: { default: true },
    sortbyName: { default: false },
  },
  data() {
    return {
      imagePath: '/img/filetypes/',
    };
  },
  computed: {
    sortedAttachments() {
      const attachments = this.attachments;

      if (!this.sortbyName) {
        return attachments;
      }

      return attachments.sort((a, b) => {
        if (
          a.displayFilename &&
          b.displayFilename &&
          a.displayFilename.toUpperCase() < b.displayFilename.toUpperCase()
        ) {
          return -1;
        }
        if (
          a.displayFilename &&
          b.displayFilename &&
          a.displayFilename.toUpperCase() > b.displayFilename.toUpperCase()
        ) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    ...mapActions(['fetchSignedUrl']),
    async openSignedUrl(attachment, windowReference) {
      const url = await this.fetchSignedUrl({
        attachmentId: attachment.id,
        downloadType: 'inline',
      });
      // console.log(url);
      // window.open(url);
      windowReference.location = url;
    },
    openUrl(attachment) {
      let windowReference = window.open('about:blank', '_blank');
      if (this.signed) {
        this.openSignedUrl(attachment, windowReference);
      } else {
        // window.open(attachment.path);
        windowReference.location = attachment.path;
      }
    },
    onDeleteAttachment(attachmentId) {
      const eventPayload = {
        attachmentId: attachmentId,
        attachmentType: this.attachmentType,
      };
      if (this.parentId) {
        eventPayload.parentId = this.parentId;
      }

      this.$emit('attachment-list:delete', eventPayload);
    },
    fileExtension(filename) {
      const filetypes = [
        'xml',
        'zip',
        'xls',
        'xlsx',
        'xlsm',
        'doc',
        'docx',
        'pdf',
        'jpg',
        'png',
        'ppt',
        'pptx',
        'csv',
      ];
      const fileType = filename.split('.').pop();
      return filetypes.includes(fileType) ? fileType : 'file';
    },
    fileImage(filename) {
      return this.imagePath + this.fileExtension(filename) + '.svg';
    },
    attachmentName(attachment) {
      return attachment.displayFilename ? attachment.displayFilename : attachment.filename;
    },
  },
};
</script>

<style scoped>
.attachment-grid {
  display: grid;
  grid-template-columns: 35px auto 35px;
  row-gap: 0.5em;
  margin-top: 0.5em;
  column-gap: 0.5em;
  vertical-align: middle;
}
.attachment-grid img {
  height: 30px;
}
.attachment-grid button {
  padding: 0 3px;
  margin-left: 8px;
  top: 1px;
  position: relative;
  height: 23px;
  width: 23px;
}
.attachment-grid a.text {
  padding-top: 5px;
}
.empty {
  margin-top: 0.5em;
}
.text {
  overflow: hidden;
}

@media (max-width: 768px) {
  .attachment-grid-container {
    display: grid;
    row-gap: 0em;
    column-gap: 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .attachment-grid {
    grid-template-columns: 35px auto;
  }
}
</style>
